import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: [
    "stage",
    "showFields",
    "showFilters",
    "viewMode",
    "compareBox",
    "filter",
    "answers",
    "sorting",
  ],
});

import database from "./database.json";
import rankings from "./rankings.json";
import authors from "./authors.json";

const sortingFunctions = {
  suggested: (a, b) => (a.rate < b.rate ? 1 : -1),
  lowestMinimumInstallment: (a, b) =>
    a.sorting.minInstallment > b.sorting.minInstallment ? 1 : -1,
  lowestSpread: (a, b) => (a.sorting.spread > b.sorting.spread ? 1 : -1),
  highestLeverage: (a, b) => (a.sorting.leverage < b.sorting.leverage ? 1 : -1),
  biggestInterestRate: (a, b) =>
    a.sorting.interestRate < b.sorting.interestRate ? 1 : -1,
  alphabetically: (a, b) => {
    if (a.slug < b.slug) {
      return -1;
    }
    if (a.slug > b.slug) {
      return 1;
    }
    return 0;
  },
  popularity: (a, b) => {
    if (!!a.popularity && !!b.popularity) {
      return b.popularity - a.popularity;
    }
    return 0;
  }
};

const store = new Vuex.Store({
  state: {
    stage: 0,
    brokers: database,
    rankings,
    authors,
    compareBox: [],
    showFields: true,
    showFilters: false,
    showInfoModal: false,
    infoModal: {
      title: null,
      content: null,
      link: null,
      to: null,
    },
    info: {
      exampleQuestionMark: {
        title: "Podręczna pomoc",
        content:
          "Tutaj znajdziesz krótkie wyjaśnienie zagadnienia inwestycyjnego. Jeśli interesują cię szczegóły zawsze poniżej znajdziesz link do pełnego artykułu.",
        to: { name: "dictionarylist" },
      },
      leverage: {
        title: "Dźwignia",
        content:
          'Dźwignia oznacza zwielokrotnienie osiąganych przez nas zysków lub strat. Na przykład dźwignia 1:100 oznacza, ze przy ruchu 1% do góry, zarabiamy 100% na pozycji długiej (buy). Dźwignia "ESMA" oznacza, że są to standardowe dźwignie narzucone przez Europejski Urząd Nadzoru Giełd i Papierów Wartościowych (ESMA).',
        link: null,
        to: { name: "definition", params: { slug: "dzwignia" } },
      },
      investmentProtection: {
        title: "Ochrona przed ujemnym saldem",
        content:
          "Jest to ochrona kapitału przed ujemnym saldem konta. Handlując z dźwignią narażasz się na straty, które mogą przewyższyć to co wpłaciłeś. Na przykład w sytuacji gdy przed zamknięciem giełdy otwierasz pozycję długą na cały kapitał a następnego dnia rynek otwiera się z luką znacznie poniżej poziomu twojego wejścia. Ochrona kapitału sprawia, że tracisz tylko to co zainwestowałeś, mimo, że twoja pozycja poszybowała jeszcze bardziej w dół i powinieneś być na minusie a broker powinien prosić się o dopłatę - do tej kwoty tego nie zrobi.",
        link: null,
        to: {
          name: "definition",
          params: { slug: "zabezpieczenie-przed-saldem-ujemnym" },
        },
      },
      platform: {
        title: "Platforma transakcyjna",
        content:
          "Platforma transakcyjna to program przez który dokonujesz transakcji na rynkach. Istnieje wiele różnych platform i różni brokerzy korzystają z różnych platform. Jeśli jesteś już zaawansowanym graczem i przyzwyczaiłeś się do konkretnej platformy, na pewno będziesz szukał brokera, który udostępnia tę platformę.",
        link: null,
        to: {
          name: "definition",
          params: { slug: "platforma-transakcyjna" },
        },
      },
      spread: {
        title: "Spread",
        content:
          'Spread jest to różnica pomiędzy ceną kupna a ceną sprzedaży danego waloru w konkretnym momencie. Wyrażany zazwyczaj w "pips". Jest to liczba stojąca na 4. miejscu po przecinku ceny waloru. Więc jeśli walor możesz kupić za 1.2360, a sprzedać za 1.2350, to spread wynosi dokładnie 10 pipsów.',
        to: {
          name: "definition",
          params: { slug: "spread" },
        },
      },
      interestRate: {
        title: "Oprocentowanie wolnych środków",
        content: "Oprocentowanie wolnych środków to procent, który otrzymujesz za trzymanie środków na koncie. Jest to dodatkowy zysk, który możesz osiągnąć na swoich inwestycjach. ",
        to: {
          name: "article",
          params: { slug: "oprocentowanie-wolnych-srodkow" },
        },
      },
      ecn: {
        title: "ECN",
        content:
          'ECN czyli Electronic Communication Network - model działania brokerów, w którym broker nie jest stroną transkacji a jedynie przekazuje twoje transakcje na rynki. Większość brokerów CFD i Forex pracuje w modelu "Market Maker" to znaczy, że twoje zlecenia nie są przekazywane na żadne rynki i tak naprawdę robisz zakład z brokerem o to czy twój walor wzrośnie lub spadnie. Występuje tu więc konflikt interesów, którego nie ma u brokerów ECN. Dostęp do ECN kosztuje więcej i wymagana minimalna wpłata początkowa jest większa. ',
        link: null,
        to: null,
      },
      fractionalShares: {
        title: "Inwestycje ułamkowe",
        content:
          'Akcje ułamkowe to części pełnych akcji, które można kupić, inwestując mniejsze kwoty. Pozwalają one inwestorom na zakup akcji nawet wtedy, gdy nie dysponują środkami na zakup całej akcji. Niektórzy brokerzy (np. XTB) oferują kupno ułamkowe także innych instrumentów niż akcje, czyli Inwestycje ułamkowe',
        link: null,
        to: {name: 'article', params: {slug: 'jak-kupic-akcje-ulamkowe'}},
      },
      kalkulatorgieldowy: {
        title: "kalkulatorgieldowy.pl",
        content:
          "Bez strachu przed fiskusem! Przeczytaj nasz artykuł o rozliczeniu podatku dochodowego od zysku z giełd zagranicznych i rozlicz się przy wsparciu kalkulatorgieldowy.pl",
        to: {
          name: "article",
          params: { slug: "podatek-od-zyskow-z-gield-zagranicznych" },
        },
      },
      thankyou: {
        title: "Dokładnie tak!",
        imgSrc: "/img/thankyou.jpg",
      },
      popularity: {
        title: "Popularność brokera",
        content: "Liczba użytkowników, która odwiedziła stronę brokera z JakiBroker.pl",
        to: {
          name: "brokerranking",
          params: { slug: "najpopularniejsi-brokerzy" },
        }
      }
    },
    viewMode: "beginner",
    sorting: "suggested",
    currentPageTitle: null,
    originalOptionsFilter: {
      accountCurrency: "Dowolna",
      countryOfRegulation: "Dowolny",
      fundingMethod: "Dowolna",
      platform: "Dowolna",
      instrument: "Dowolne",
      ecn: "Dowolnie",
      kalkulatorgieldowy: "Dowolnie",
      fractionalShares: "Dowolnie",
    },
    filter: {
      accountCurrency: "Dowolna",
      countryOfRegulation: "Dowolny",
      fundingMethod: "Dowolna",
      platform: "Dowolna",
      instrument: "Dowolne",
      ecn: "Dowolnie",
      kalkulatorgieldowy: "Dowolnie",
      fractionalShares: "Dowolnie",
      interestRate: "Dowolnie",
    },
    fastOnlineWireTransfers: {
      payu: null,
      blik: null,
      przelewy24: null,
      "apple pay": null
    },
    questions: {
      1: {
        text: "Jestem",
        options: [
          { text: "początkujący", id: 1 },
          { text: "średniozaawansowany", id: 2 },
          { text: "zaawansowany", id: 3 },
        ],
      },
      2: {
        text: "Chcę handlować na platformie",
        helpDefinition: "platform",
        options: [
          { text: "Dowolna", id: 5 },
          { text: "MetaTrader 4", id: 1 },
          { text: "MetaTrader 5", id: 2 },
          { text: "NinjaTrader", id: 4 },
          { text: "WebTrader", id: 3 },
          { text: "xStation 5", id: 6 },
          { text: "xStation Mobile", id: 7 },
        ],
      },
      3: {
        text: "Chcę handlować",
        options: [
          { text: "codziennie", id: 2 },
          { text: "kilka razy w miesiącu", id: 3 },
          { text: "sporadycznie", id: 4 },
          { text: "kilka razy w roku", id: 1 },
        ],
      },
    },
    answers: {
      0: null,
      1: null,
      2: null,
      3: null,
    },
  },
  mutations: {
    showInfoModalWith(state, what) {
      state.infoModal.title = state.info[what].title;
      if (state.info[what].imgSrc) {
        state.infoModal.imgSrc = state.info[what].imgSrc;
      } else {
        state.infoModal.imgSrc = null;
        state.infoModal.content = state.info[what].content;
        state.infoModal.link = state.info[what].link;
        state.infoModal.to = state.info[what].to;
      }
      state.showInfoModal = true;
    },
    closeInfoModal(state) {
      state.showInfoModal = false;
    },
    setCurrentPageTitle(state, payload) {
      state.currentPageTitle = payload;
    },
    resetForm(state) {
      state.stage = 0;
      for (let key in state.answers) {
        Vue.set(state.answers, key, null);
      }
      for (let key in state.filter) {
        Vue.set(state.filter, key, state.originalOptionsFilter[key]);
      }
    },
    setViewMode(state, payload) {
      state.viewMode = payload;
    },
    setAnswer(state, payload) {
      Vue.set(state.answers, payload.stage, payload.value);
    },
    incrementStage(state) {
      state.stage++;
    },
    decrementStage(state) {
      state.stage--;
    },
    setSorting(state, payload) {
      state.sorting = payload;
    },
    setFilter(state, payload) {
      Vue.set(state.filter, payload.key, payload.value);
    },
    resetAllFilters(state) {
      for (let key of Object.keys(state.filter)) {
        Vue.set(state.filter, key, state.originalOptionsFilter[key]);
      }
    },
    resetFilter(state, payload) {
      Vue.set(state.filter, payload, state.originalOptionsFilter[payload]);
    },
    setAccountCurrency(state, payload) {
      Vue.set(state.filter, "accountCurrency", payload);
    },
    setCountryOfRegulation(state, payload) {
      Vue.set(state.filter, "countryOfRegulation", payload);
    },
    setFundingMethod(state, payload) {
      Vue.set(state.filter, "fundingMethod", payload);
    },
    setPlatform(state, payload) {
      Vue.set(state.filter, "platform", payload);
    },
    setInstrument(state, payload) {
      Vue.set(state.filter, "instrument", payload);
    },
    setEcn(state, payload) {
      Vue.set(state.filter, "ecn", payload);
    },
    setKalkulatorgieldowy(state, payload) {
      Vue.set(state.filter, "kalkulatorgieldowy", payload);
    },
    setFractionalShares(state, payload) {
      Vue.set(state.filter, "fractionalShares", payload);
    },
    setInterestRate(state, payload) {
      Vue.set(state.filter, "interestRate", payload);
    },
    addToCompareBox(state, payload) {
      state.compareBox.push(payload);
    },
    removeFromCompareBox(state, payload) {
      state.compareBox = state.compareBox.filter((item) => item !== payload);
    },
  },
  actions: {
    nextQuestion({ commit, state }, payload) {
      if (payload.value !== undefined) {
        commit("setAnswer", { stage: state.stage, value: payload.value });
      }
      commit("incrementStage");
    },
    prevQuestion({ commit, state }, payload) {
      if (payload.value !== undefined) {
        state.commit;
      }
      commit("decrementStage");
    },
  },
  getters: {
    getCompareBox(state) {
      return state.compareBox;
    },
    allAccountCurrencies(state) {
      let currencies = {};
      for (let brokerIndex in state.brokers) {
        for (let currencyIndex in state.brokers[brokerIndex].currencies) {
          const currency = state.brokers[brokerIndex].currencies[currencyIndex];
          if (currency != "PLN") {
            currencies[
              state.brokers[brokerIndex].currencies[currencyIndex]
            ] = null;
          }
        }
      }
      let sortedCurrencies = Object.keys(currencies);
      sortedCurrencies.sort();
      sortedCurrencies.unshift("PLN");
      return sortedCurrencies;
    },
    allFundingMethods(state) {
      let fundingMethods = { "Szybkie przelewy online": null };
      for (let brokerIndex in state.brokers) {
        for (let fundingMethodIndex in state.brokers[brokerIndex]
          .fundingMethods) {
          fundingMethods[
            state.brokers[brokerIndex].fundingMethods[fundingMethodIndex]
          ] = null;
        }
      }
      let sortedFundingMethods = Object.keys(fundingMethods);
      sortedFundingMethods.sort();
      return sortedFundingMethods;
    },
    allCountryOfRegulation(state) {
      let countries = {};
      for (let brokerIndex in state.brokers) {
        for (let countryIndex in state.brokers[brokerIndex].countryOfRegulation) {
          countries[
            state.brokers[brokerIndex].countryOfRegulation[countryIndex]
          ] = null;
        }
      }
      let sortedCountries = Object.keys(countries).filter(Boolean);
      sortedCountries.sort();
      // add "Dowolny" in the beginning
      sortedCountries.unshift("Dowolny");
      return sortedCountries;
    },
    allPlatforms(state) {
      let platforms = {};
      for (let brokerIndex in state.brokers) {
        for (let platformIndex in state.brokers[brokerIndex].platforms) {
          platforms[state.brokers[brokerIndex].platforms[platformIndex]] = null;
        }
      }
      let sortedPlatforms = Object.keys(platforms);
      sortedPlatforms.sort();
      // add "Dowolna" in the beginning
      sortedPlatforms.unshift("Dowolna");
      return sortedPlatforms;
    },
    sorting(state) {
      return state.sorting;
    },
    databaseBrokers(state) {
      return state.brokers;
    },
    filteredBrokers(state, getters) {
      // filtering
      let brokers = state.brokers;
      if (
        state.filter.accountCurrency !== null &&
        state.filter.accountCurrency != "Dowolna"
      ) {
        brokers = brokers.filter(
          (item) =>
            item.currencies.findIndex(
              (currency) =>
                currency.toLowerCase() ==
                state.filter.accountCurrency.toLowerCase()
            ) >= 0
        );
      }

      if (
        state.filter.countryOfRegulation !== null &&
        state.filter.countryOfRegulation != "Dowolny"
      ) {
        brokers = brokers.filter(
          (item) =>
            item.countryOfRegulation.findIndex(
              (country) =>
                country.toLowerCase() ==
                state.filter.countryOfRegulation.toLowerCase()
            ) >= 0
        );
      }

      if (
        state.filter.platform !== null &&
        state.filter.platform != "Dowolna"
      ) {
        brokers = brokers.filter(
          (item) =>
            item.platforms.findIndex(
              (platform) =>
                platform.toLowerCase() == state.filter.platform.toLowerCase()
            ) >= 0
        );
      }

      if (
        state.filter.fundingMethod !== null &&
        state.filter.fundingMethod != "Dowolna"
      ) {
        if (
          state.filter.fundingMethod.toLowerCase() == "szybkie przelewy online"
        ) {
          brokers = brokers.filter(
            (item) =>
              item.fundingMethods.findIndex(
                (fundingMethod) =>
                  state.fastOnlineWireTransfers[fundingMethod.toLowerCase()] !==
                  undefined
              ) >= 0
          );
        } else {
          brokers = brokers.filter(
            (item) =>
              item.fundingMethods.findIndex(
                (fundingMethod) =>
                  fundingMethod.toLowerCase() ==
                  state.filter.fundingMethod.toLowerCase()
              ) >= 0
          );
        }
      }

      if (state.filter.ecn !== null && state.filter.ecn != "Dowolnie") {
        const ecn = state.filter.ecn.toLowerCase() == "tak";
        brokers = brokers.filter((item) => item.ecn === ecn);
      }

      if (
        state.filter.kalkulatorgieldowy !== null &&
        state.filter.kalkulatorgieldowy != "Dowolnie"
      ) {
        const kalkulatorgieldowy =
          state.filter.kalkulatorgieldowy.toLowerCase() == "tak";
        brokers = brokers.filter(
          (item) => !!item.kalkulatorgieldowy == kalkulatorgieldowy
        );
      }

      if (state.filter.fractionalShares !== null && state.filter.fractionalShares != "Dowolnie") {
        const fractionalSharesPredicate = state.filter.fractionalShares.toLowerCase() == "tak";
        brokers = brokers.filter((item) => item.fractionalShares === fractionalSharesPredicate);
      }

      if (state.filter.interestRate !== null && state.filter.interestRate !== undefined && state.filter.interestRate != "Dowolnie") {
        const interestRatePredicate = state.filter.interestRate.toLowerCase() == "tak";
        brokers = brokers.filter((item) => (item.interestRate.length > 0) && interestRatePredicate);
      }

      const instrumentCompare = (instrument) => {
        let instrumentLowerCase = instrument.toLowerCase();
        if (instrumentLowerCase.endsWith(" (cfd)"))
          instrumentLowerCase = instrumentLowerCase.slice(0, -6);
        const filterLowerCase = state.filter.instrument.toLowerCase();
        return instrumentLowerCase == filterLowerCase;
      };

      if (
        state.filter.instrument !== null &&
        state.filter.instrument != "Dowolne"
      ) {
        brokers = brokers.filter(
          (item) => item.instruments.findIndex(instrumentCompare) >= 0
        );
      }
      if (
        state.viewMode !== null &&
        state.viewMode != "custom" &&
        state.viewMode != "full"
      ) {
        brokers = brokers.filter(
          (item) =>
            item.tradingLevel.findIndex((level) => level == state.viewMode) >= 0
        );
      }

      // sorting
      brokers = brokers.sort(sortingFunctions[getters.getSorting]);
      return brokers;
    },
    getFilter(state) {
      return state.filter;
    },
    getSorting(state) {
      return state.sorting;
    },
    getRankingList(state) {
      return state.rankings.filter((item) => item.isPublic || window.location.origin.includes("localhost") || window.location.origin.includes("jakibroker-testing"));
    }
  },
  plugins: [vuexLocal.plugin],
});

export default store;
