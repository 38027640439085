<template>
    <section class="section">
        <div class="columns">
            <div class="column is-3"></div>
            <div class="column">
                <h2 class="title has-fancy-underline has-text-centered">
                    <span>404</span>
                </h2>
                <h3 class="title has-fancy-underline has-text-centered">
                    <span>Strony nie znaleziono</span>
                </h3>

                <div class="columns">
                    <div class="column">

                        <ArticleWidget :article="suggestedArticle1"></ArticleWidget>
                    </div>
                    <div class="column has-text-centered">
                        <lord-icon class="big-icon" animation="auto"
                            src="/lordicon/wired-lineal-453-savings-pig-hover-pinch.json"></lord-icon>
                        <div class="has-text-centered">
                            <p>Dołącz do <strong class="has-fancy-underline">9 141 osób</strong>, które znalazły brokera
                                przez
                                JakiBroker.pl</p>
                            <router-link :to="{ name: 'landing' }">
                                <button class="button is-large is-info is-rounded cta-button">
                                    <span class="icon">
                                        <lord-icon animation="hover" target="button" class="big-icon" params="100"
                                            palette="#ffffff;#ffffff"
                                            src="/lordicon/312-avatar-icon-calm-search-outline.json"></lord-icon>
                                    </span>
                                    <span>ZNAJDŹ BROKERA</span>
                                </button>
                            </router-link>
                        </div>

                    </div>
                </div>
            </div>
            <div class="column is-2"></div>
        </div>
    </section>
</template>

<script>
import articles from "@/articles.json";
import ArticleWidget from "./ArticleWidget";

export default {
    name: "NotFound",
    data() {
        return {
            articles: articles.filter((elem) => elem.isPublic || window.location.origin.includes("localhost") || window.location.origin.includes("jakibroker-testing")),
        }
    },
    computed: {
        suggestedArticle1() {
            return this.articles[0]
        }
    },
    components: {
        ArticleWidget
    }
}
</script>

<style scoped>
.small-icon {
    width: 48px;
    height: 48px;
}

.big-icon {
    width: 256px;
    height: 256px;
}
</style>